import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { Helmet } from 'react-helmet-async';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: '100px',
  paddingBottom: '100px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  position: 'relative',
  zIndex: 1,
  background: 'url(/assets/background-sky.png)',
}));

const ThankYouMessage = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '2.5rem',
  marginBottom: '24px',
  textShadow: '1px 1px 2px black, 0 0 1em black, 0 0 0.2em black',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontSize: '1.2rem',
  padding: '10px 24px',
  marginTop: '24px',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const ThankYouPage = () => {
  const { width, height } = useWindowSize(); // Hook to get window size for Confetti
  
  return (
    <StyledContainer>
       <Helmet>
        <title>Thank you for contacting Living Faith Christian Fellowship!!</title>
        <meta name="description" content="Thank you for contacting Living Faith Christian Fellowship! Hoping you a blessed day!" />
        <link rel="canonical" href="https://livingfaithchristianfellowship.com/thank-you" />
      </Helmet>
      <Confetti
        width={width}
        height={height}
        numberOfPieces={200}
        gravity={0.3}
      />
      <ThankYouMessage variant="h2">
        Thank You for Your Submission!
      </ThankYouMessage>
      <Typography variant="h6" sx={{ color: 'gray', marginBottom: '32px' }}>
        We have received your message and will get back to you shortly.
      </Typography>
      <Box>
        <StyledButton href="/">
          Go Back Home
        </StyledButton>
      </Box>
    </StyledContainer>
  );
};

export default ThankYouPage;
