import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const SectionTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '5rem',
  fontWeight: 300,
  color: 'white',
  marginBottom: '40px',
  paddingTop: 100,
  marginLeft: 20,
  letterSpacing: '45px',
  textShadow: '1px 1px 0 black, -1px -1px 0 rgba(255, 255, 255, 0.5), 1px -1px 0 black, -1px 1px 0 rgba(255, 255, 255, 0.5), 1px 1px 2px rgba(0, 0, 0, 0.5)', 

  [theme.breakpoints.down('lg')]: {
    fontSize: '4rem',
    letterSpacing: '40px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '3rem',
    letterSpacing: '35px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
    letterSpacing: '15px',
  },
}));

export default SectionTitle;
