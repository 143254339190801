import React, { useState, useEffect, useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import { styled, useTheme } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross } from '@fortawesome/free-solid-svg-icons';
import menuLogo from '../assets/menu.png';

const StyledToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
  padding: '0 16px',
});

const MobileMenuIcon = styled(IconButton)({
  display: 'block',
});

const CustomListItemText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: 'white',
  fontSize: '1.4rem',
  textAlign: 'center',
  fontWeight: '500',
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    background: 'linear-gradient(45deg, #074164, #0c2438, #082d44, #0d79d1)',
    color: 'white',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.3s ease-in-out',
    padding: theme.spacing(2),
  },
}));

const CloseIconWrapper = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: 'white',
  fontSize: '30px',
}));

const DrawerContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  textAlign: 'center',
  width: '100%',
  height: '100%', 
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(45deg, #ffffff, #e0f7fa)',
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
  fontFamily: 'Dancing Script, cursive',
  fontSize: '2.4rem',
  fontWeight: 'bold',
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  width: '100%',
  color: 'white',
}));

const StyledMenuLogo = styled('img')({
  width: '40px', 
  height: '40px',
  cursor: 'pointer',
  objectFit: 'contain',
  filter: 'invert(1)',
});

const NavBar = () => {
  const theme = useTheme();
  const [scrolling, setScrolling] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;
    const isScrollingUp = prevScrollPos > currentScrollPos;
    setVisible(isScrollingUp || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);

    if (currentScrollPos > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  }, [prevScrollPos]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar
        position="fixed"
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '80px',
          transform: visible ? 'translateY(0)' : 'translateY(-100%)',
          transition: 'transform 0.3s ease',
          backgroundColor: scrolling
            ? `rgba(${theme.palette.primary.main}, 0.8)`
            : 'rgba(15, 24, 32, 0.6)',
          backdropFilter: 'blur(10px)',
        }}
      >
        <StyledToolbar>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, alignItems: 'center' }}>
            <Link to="/">
              <FontAwesomeIcon icon={faCross} inverse size="2x" />
            </Link>
            <Typography
              variant="h4"
              sx={{
                fontFamily: 'DancingScript',
                color: 'white',
                fontSize: { xs: '1.4rem', md: '2rem' },
              }}
            >
              Living Faith Christian Fellowship
            </Typography>
          </Box>
          <MobileMenuIcon edge="end" aria-label="menu" onClick={toggleDrawer(true)}>
            <StyledMenuLogo src={menuLogo} alt="Menu Logo" />
          </MobileMenuIcon>
        </StyledToolbar>
      </AppBar>

      <StyledDrawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <CloseIconWrapper onClick={toggleDrawer(false)}>
          <CloseIcon fontSize="large" />
        </CloseIconWrapper>
        <DrawerContent>
          <GradientText>Living Faith Christian Fellowship</GradientText>
          <List>
            <ListItem listitembutton="true" component={Link} to="/" onClick={toggleDrawer(false)}>
              <CustomListItemText>Home</CustomListItemText>
            </ListItem>
            <Divider />
            <ListItem listitembutton="true" component={Link} to="/about" onClick={toggleDrawer(false)}>
              <CustomListItemText>About</CustomListItemText>
            </ListItem>
            <Divider />
            <ListItem listitembutton="true" component={Link} to="/schedule" onClick={toggleDrawer(false)}>
              <CustomListItemText>Schedule</CustomListItemText>
            </ListItem>
            <Divider />
            <ListItem listitembutton="true" component={Link} to="/contact" onClick={toggleDrawer(false)}>
              <CustomListItemText>Contact</CustomListItemText>
            </ListItem>
            <Divider />
            <ListItem listitembutton="true" component={Link} to="/privacy-policy-and-terms-of-service" onClick={toggleDrawer(false)}>
              <CustomListItemText>Privacy Policy</CustomListItemText>
            </ListItem>
            <Divider />
            <ListItem listitembutton="true" component={Link} to="/privacy-policy-and-terms-of-service" onClick={toggleDrawer(false)}>
              <CustomListItemText>Terms of Service</CustomListItemText>
            </ListItem>
          </List>

          <Footer>
            <Typography variant="body2">
              &copy; {new Date().getFullYear()} Living Faith Christian Fellowship. All rights reserved.
            </Typography>
            <Typography variant="body2">
              {currentTime.toLocaleDateString()} {currentTime.toLocaleTimeString()}
            </Typography>
          </Footer>
        </DrawerContent>
      </StyledDrawer>
    </>
  );
};

export default NavBar;
