import React, { useEffect, useState } from 'react';
import { Button, Container, Typography, Box } from '@mui/material';

const CalendarView = () => {
  const [isError, setIsError] = useState(false);

  const loadCalendarScript = () => {
    setIsError(false); // Reset error state before trying to load the script

    // Check if the Tockify script already exists
    if (!document.querySelector('[data-tockify-script="embed"]')) {
      const script = document.createElement('script');
      script.setAttribute('data-cfasync', 'false');
      script.setAttribute('data-tockify-script', 'embed');
      script.src = `https://public.tockify.com/browser/embed.js?cacheBuster=${Date.now()}`;

      // On script load, check if it loaded correctly
      script.onload = () => {
        console.log('Tockify script loaded successfully');
        setIsError(false); // Script loaded successfully, reset error
      };

      // Handle script loading error
      script.onerror = () => {
        console.error('Tockify script failed to load');
        setIsError(true); // Set error state if script fails to load
      };

      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    loadCalendarScript(); // Load the calendar script on component mount
  }, []);

  const handleRetry = () => {
    // Open the calendar in a new tab
    window.open('https://tockify.com/living.faith.christian.fellowship', '_blank');
  };

  return (
    <Container maxWidth="md" sx={{ padding: '20px', textAlign: 'center' }}>
      {!isError ? (
        <Box
          data-tockify-component="calendar"
          data-tockify-calendar="living.faith.christian.fellowship"
          sx={{
            minHeight: '600px',
            width: '100%',
            marginBottom: '20px',
          }}
        />
      ) : (
        <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
          <Typography variant="h6" color="error" gutterBottom>
            Calendar failed to load. You can view it in a new tab.
          </Typography>
          <Button
            onClick={handleRetry}
            variant="contained"
            color="primary"
            sx={{ padding: '10px 20px', fontSize: '16px' }}
          >
            Open Calendar
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default CalendarView;
