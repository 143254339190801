import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, Paper, CardMedia } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

const EventCard = styled(Paper)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  width: '600px',
  maxWidth: '600px',
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  transition: 'transform 0.4s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
  },

  [theme.breakpoints.down('sm')]: {
    width: '80vw',
    maxWidth: 'none', 
  },
}));

const ImageOverlay = styled(Box)({
  background: 'rgba(0, 0, 0, 0.5)',
  color: '#fff',
  padding: '12px',
  textAlign: 'center',
});

const EventDetails = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(3),
  backgroundColor: '#fff',
  '& h5': {
    fontFamily: 'Oswald, sans-serif',
  },
  '& p': {
    fontFamily: 'Oswald, sans-serif',
    color: 'black',
    marginBottom: theme.spacing(1),
  },
}));

const cardVariants = {
  hidden: { opacity: 0, x: -100, rotate: -5 },
  visible: {
    opacity: 1,
    x: 0,
    rotate: 0,
    transition: { duration: 0.6, ease: 'easeOut' },
  },
};

const EventGrid = () => {
  const [visibleCards, setVisibleCards] = useState({});

  const observeCards = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setVisibleCards((prev) => ({ ...prev, [entry.target.id]: true }));
      }
    });
  };

  const observer = useRef(
    new IntersectionObserver(observeCards, {
      threshold: 0.2,
    })
  );

  const cardRefs = useRef([]);

  useEffect(() => {
    const currentObserver = observer.current;
    const currentCardRefs = cardRefs.current;

    currentCardRefs.forEach((card) => {
      if (card) {
        currentObserver.observe(card);
      }
    });

    return () => {
      currentCardRefs.forEach((card) => {
        if (card) {
          currentObserver.unobserve(card);
        }
      });
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '100vh',
        padding: 4,
      }}
    >
      {[
        {
          id: 'card1',
          image: '/assets/LFCF-IMAGES/FridayYouthNight/YouthNight1.webp',
          title: 'Sunday Worship Service',
          details: ['Every Sunday', '10:00 AM - 11:30 AM'],
        },
        {
          id: 'card2',
          image: '/assets/LFCF-IMAGES/FridayYouthNight/YouthNight2.webp',
          title: 'Wednesday Prayer Gathering',
          details: ['Every Wednesday', '6:30 PM - 7:30 PM'],
        },
        {
          id: 'card3',
          image: '/assets/LFCF-IMAGES/FridayYouthNight/YouthNight3.webp',
          title: 'Friday Youth Nights',
          details: ['Every Friday', '7:00 PM - 8:30 PM'],
        },
        {
          id: 'card4',
          image: '/assets/LFCF-IMAGES/CellGroupMeetings/Cell3.webp',
          title: 'Monday-Friday Cell Group',
          details: ['Every Monday through Friday', '6:30 PM - 8:00 PM'],
        },
        {
          id: 'card5',
          image: '/assets/LFCF-IMAGES/CellGroupMeetings/Cell2.webp',
          title: 'Ministry Meeting',
          details: ['Every Wednesday', '6:30 PM - 8:00 PM'],
        },
        {
          id: 'card6',
          image: '/assets/LFCF-IMAGES/Misc/1.webp',
          title: 'Young Adult Fellowship',
          details: ['Every other Monday', 'Time of day varies']
        },
      ].map((event, index) => (
        <motion.div
          key={event.id}
          ref={(el) => (cardRefs.current[index] = el)}
          id={event.id}
          variants={cardVariants}
          initial="hidden"
          animate={visibleCards[event.id] ? 'visible' : 'hidden'}
        >
          <EventCard>
            <CardMedia loading="lazy" component="img" height="400" image={event.image} alt={event.title} />
            <ImageOverlay>
              <Typography variant="h5" component="h5">
                {event.title}
              </Typography>
            </ImageOverlay>
            <EventDetails>
              {event.details.map((detail, i) => (
                <Typography key={i} variant="body1">
                  {detail}
                </Typography>
              ))}
            </EventDetails>
          </EventCard>
        </motion.div>
      ))}
    </Box>
  );
};

export default EventGrid;
