import React from 'react';
import { Box } from '@mui/material';
import HighlightReel from '../components/HighlightReel';
import Map from '../components/Map';
import ContactForm from '../components/ContactForm';
import HeroSection from '../components/HeroSection';

const LandingPage = () => {
  return (
    <div className="bg-test">
      <HeroSection
        videoSrc='/assets/LFCF-IMAGES/Hero Video/1.mp4'
        title="Welcome to Living Faith Christian Fellowship"
        scrollToId="#Highlights"
      />

      <Box sx={{ paddingBottom: 30 }}>
        <HighlightReel />
        <Map />
      </Box>
      <ContactForm />
    </div>
  );
};

export default LandingPage;
