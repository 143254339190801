import React from 'react';
import { Box, Typography, Link, IconButton, Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import {Link as RouterLink} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicyTerms = () => {
  return (
    <Box sx={{ background: 'white',padding: '24px', maxWidth: '800px', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 10 }}>
       <Helmet>
        <title>Privacy Policy & Terms of Service | Living Faith Christian Fellowship</title>
        <meta name="description" content="Review the privacy policy and terms of service of Living Faith Christian Fellowship, located in Virginia Beach. Learn how we handle your information and the terms for using our services." />
        <link rel="canonical" href="https://livingfaithchristianfellowship.com/privacy-policy-and-terms-of-service" />
      </Helmet>
      <Typography variant="h2" gutterBottom style={{ fontWeight: 'bold' }}>
        Privacy Policy & Terms of Service
      </Typography>

      <Typography variant="h4" gutterBottom>
        Introduction
      </Typography>
      <Typography variant="body1" paragraph>
        Living Faith Christian Fellowship (LFCF), located in Virginia Beach, is a non-profit church
        committed to providing a positive and spiritually enriching experience for all visitors, both
        online and in-person. This Privacy Policy and Terms of Service outline the practices regarding
        how we collect, use, and safeguard any information you may share with us, as well as the terms
        governing your use of our services.
      </Typography>
      <Typography variant="body1" paragraph>
        By interacting with our website or attending our events, you agree to the terms outlined below.
      </Typography>

      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Information We Collect
      </Typography>
      <Typography variant="body1" paragraph>
        We may collect personal information that you provide to us voluntarily. This may include, but is not
        limited to:
      </Typography>
      <ul>
        <li>Your name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Mailing address</li>
        <li>Any other information you provide through contact forms, event sign-ups, or prayer requests</li>
      </ul>

      <Typography variant="h6" gutterBottom>
        2. How We Use Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        The information we collect may be used to:
      </Typography>
      <ul>
        <li>Respond to your inquiries</li>
        <li>Provide updates about our church services, events, and programs</li>
        <li>Send newsletters and other church-related communications</li>
        <li>Improve our website and services</li>
        <li>Pray for your needs, if you provide prayer requests</li>
      </ul>

      <Typography variant="body1" paragraph>
        We will never sell, share, or distribute your personal information to third parties unless required by law
        or with your explicit consent.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. Data Security
      </Typography>
      <Typography variant="body1" paragraph>
        We take the security of your information seriously. We use appropriate security measures to protect your
        personal information from unauthorized access, disclosure, or alteration. However, please understand that
        no method of transmission over the internet is 100% secure, and we cannot guarantee the absolute security
        of your data.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Cookies
      </Typography>
      <Typography variant="body1" paragraph>
        Our website may use cookies to enhance your browsing experience. These are small files stored on your device
        that help us understand your preferences and usage patterns. You can choose to disable cookies through your
        browser settings, though some features of the website may not function properly if cookies are disabled.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Links to External Sites
      </Typography>
      <Typography variant="body1" paragraph>
        Our website may contain links to external sites, such as social media platforms or resources. Please note
        that we are not responsible for the privacy practices of these external sites. We encourage you to review
        their privacy policies when visiting their platforms.
      </Typography>

      <Typography variant="h4" gutterBottom>
        Terms of Service
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Use of Our Website
      </Typography>
      <Typography variant="body1" paragraph>
        By using our website, you agree to:
      </Typography>
      <ul>
        <li>Use the site for lawful purposes only</li>
        <li>Not engage in any behavior that could harm or disrupt the site, its content, or its users</li>
        <li>Not attempt to gain unauthorized access to any part of the site or its services</li>
      </ul>

      <Typography variant="h6" gutterBottom>
        2. Intellectual Property
      </Typography>
      <Typography variant="body1" paragraph>
        All content on our website, including text, images, graphics, and logos, is the property of Living Faith
        Christian Fellowship, unless otherwise noted. You may not reproduce, distribute, or use our content without
        explicit written permission.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. Service Modifications
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to modify or discontinue any aspect of our website or services without prior notice.
        This includes, but is not limited to, the content, functionality, and availability of the website.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        Living Faith Christian Fellowship is not liable for any damages or losses that may arise from the use of our
        website, services, or resources. While we strive to provide accurate and helpful information, we make no
        guarantees regarding the completeness or accuracy of the content provided.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Changes to These Policies
      </Typography>
      <Typography variant="body1" paragraph>
        We may update this Privacy Policy and Terms of Service as needed. Any changes will be posted on this page,
        and your continued use of the website following such changes constitutes your agreement to the updated terms.
      </Typography>

      <Typography variant="h4" gutterBottom>
        Contact Information
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns about this Privacy Policy or Terms of Service, please contact us:
      </Typography>
      <Box display="flex" flexDirection="column" mt={2}>
        <Typography variant="body1">
          <IconButton aria-label="phone" color="inherit">
            <PhoneIcon />
          </IconButton>
          <Link href="tel:+17575199763" color="inherit">
            +1 757 519 9763
          </Link>
        </Typography>
        <Typography variant="body1">
          <IconButton aria-label="email" color="inherit">
            <EmailIcon />
          </IconButton>
          <Link href="mailto:support@livingfaithchristianfellowship.com" color="inherit">
            support@livingfaithchristianfellowship.com
          </Link>
        </Typography>
        <Typography variant="body1">
          <IconButton aria-label="facebook" color="inherit" component={Link} href="https://www.facebook.com/profile.php?id=100072013372431" target="_blank">
            <FacebookIcon />
          </IconButton>
          <Link href="https://www.facebook.com/profile.php?id=100072013372431" color="inherit" target="_blank">
            Visit us on Facebook
          </Link>
        </Typography>
      </Box>

      <Typography variant="body2" mt={4}>
        &copy; {new Date().getFullYear()} Living Faith Christian Fellowship. All rights reserved.
      </Typography>
    <RouterLink to="/" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>  
    <Button 

    sx={{ width: '100px', border: '2px solid black', marginTop: 5}}>
      Home
    </Button>
    </RouterLink>
    </Box>
  );
};

export default PrivacyPolicyTerms;
