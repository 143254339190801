import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      default: '#ffffff',
      main: '#5da444',
      scroll: '#fffff6'
    },
    secondary: {
      main: '#a83f39', 
    },
    tertiary: {
      default: '#ffffff',
      main: '#54a5e0', 
    },
    text: {
      default: '#ffffff',
      primary: '#000000',
      secondary: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Oswald, sans-serif',
    button: {
      fontFamily: 'DancingScript',
      fontWeight: 1000,
      fontSize: '1rem',
    },
    h6: {
      color: '#5da444',
    },
    body1: {
      color: '#000000',
    },
  },
});

export default theme;
