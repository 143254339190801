import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import About from './pages/About';
import Schedule from './pages/Schedule';
import Contact from './pages/Contact';
import ContactForm from './components/ContactForm';
import PrivacyPolicyTerms from './pages/PrivacyPolicyTerms';
import ThankYouPage from './pages/ThankYouPage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/home" element={<LandingPage />} />
      <Route path="/about" element={<About />} />
      <Route path="/schedule" element={<Schedule />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/contact-form" element={<ContactForm />} />
      <Route path="/privacy-policy-and-terms-of-service" element={<PrivacyPolicyTerms />} />
      <Route path="/thank-you" element={<ThankYouPage />} />
      <Route path="/*" element={<LandingPage />} />
    </Routes>
  );
};

export default AppRoutes;
