import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Box, Typography, Button, Link, Tooltip } from '@mui/material';
import L from 'leaflet';
import ChurchIcon from '@mui/icons-material/Church';
import FacebookIcon from '@mui/icons-material/Facebook';
import PhoneIcon from '@mui/icons-material/Phone';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SectionTitle from './SectionTitle';
import 'leaflet/dist/leaflet.css';

const handleCopyToClipboard = () => {
  const address = "5257 Challedon Dr, Virginia Beach, VA 23462, United States";

  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(address)
      .then(() => alert("Address copied to clipboard!"))
      .catch(() => alert("Failed to copy address"));
  } else {
    const textarea = document.createElement("textarea");
    textarea.value = address;
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");
      alert("Address copied to clipboard!");
    } catch (err) {
      alert("Failed to copy address");
    }
    document.body.removeChild(textarea);
  }
};

const containerStyle = {
  width: 'auto',
  maxWidth: '600px',
  height: '400px',
  marginTop: '24px',
  marginBottom: '24px',
  border: '1px solid #ccc',
  borderRadius: '8px',
  overflow: 'hidden',
  margin: '0 auto',
};

const center = [36.840390, -76.172480];

const customIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const Map = () => {
  return (
    <>
      <SectionTitle id="OurSchedule" variant="h2">
        OUR LOCATION
      </SectionTitle>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, paddingTop: 20}}>
      <Box
        component="img"
        alt="Living Faith Christian Fellowship Location"
        src="/assets/LFCF-IMAGES/Misc/6.webp"
        sx={{height: 400, width: 'auto', borderRadius: 10}}
        />
        <Box
        component="img"
        alt="Living Faith Christian Fellowship Location"
        src="/assets/LFCF-IMAGES/Misc/7.webp"
        sx={{height: 400, width: 'auto', borderRadius: 10}}
        />
        </Box>

        <SectionTitle>MAP</SectionTitle>
      <Box sx={{ ...containerStyle }}>
        <MapContainer
          center={center}
          zoom={15}
          style={{ height: '100%', width: '100%' }}
          dragging={false}
          zoomControl={false}
          scrollWheelZoom={false}
          doubleClickZoom={false}
          touchZoom={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={center} icon={customIcon}>
            <Popup>
              <Box display="flex" alignItems="center" mb={1}>
                <ChurchIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                <Typography variant="body1" component="span">
                  Living Faith Christian Fellowship
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="body2" component="span">
                  5257 Challedon Dr, Virginia Beach, VA 23462, United States
                </Typography>
                <Tooltip title="Copy to clipboard">
                  <Button onClick={handleCopyToClipboard} size="small" sx={{ marginLeft: '8px' }}>
                    <ContentCopyIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </Box>
              <Box display="flex" flexDirection="column" gap={1}>
                <Button
                  variant="contained"
                  startIcon={<FacebookIcon />}
                  component={Link}
                  href="https://www.facebook.com/profile.php?id=100072013372431"
                  target="_blank"
                  sx={{ color: 'white !important' }}
                >
                  Visit us on Facebook
                </Button>

                <Button
                  variant="outlined"
                  startIcon={<PhoneIcon />}
                  href="tel:+17575199763"
                >
                  Call Us
                </Button>

                <Button
                  variant="outlined"
                  startIcon={<GpsFixedIcon />}
                  component={Link}
                  href="https://maps.app.goo.gl/WXxthC1Hm95Gfis46"
                  target="_blank"
                >
                  Open in Google Maps
                </Button>
              </Box>
            </Popup>
          </Marker>
        </MapContainer>
      </Box>
    </>
  );
};

export default Map;
