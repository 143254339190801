import React, { useEffect, useState } from 'react';
import { Box, Link, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const StyledFooter = styled(Box)`
  background-color: #583c17;
  color: white;
  padding: 16px 32px;
  text-align: left;
  transition: background 0.3s ease;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const FooterLink = styled(Link)({
  color: 'white',
  fontFamily: 'Oswald, sans-serif',
  margin: '0 8px',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const SocialIcons = styled(Box)({
  display: 'flex',
  margin: '16px 0',
});

const FooterTypography = styled('p')({
  fontFamily: 'Oswald, sans-serif',
  margin: '8px 0',
});

const Footer = () => {
  const [backgroundImage, setBackgroundImage] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = '/assets/footer-bg.png';
    img.onload = () => setBackgroundImage('/assets/footer-bg.png');
  }, []);

  return (
    <StyledFooter
      sx={{
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
      }}
    >
      <FooterTypography style={{ fontSize: '24px', fontWeight: 'bold' }}>
        Living Faith Christian Fellowship
      </FooterTypography>
      <FooterTypography style={{ fontSize: '16px' }}>
        <IconButton aria-label="phone" color="inherit" component="a" href="tel:+17575199763">
          <PhoneIcon />
        </IconButton>
        +1 757 519 9763
      </FooterTypography>
      <FooterTypography style={{ fontSize: '16px' }}>
        <IconButton aria-label="email" color="inherit" component="a" href="mailto:support@livingfaithchristianfellowship.com">
          <EmailIcon />
        </IconButton>
        support@livingfaithchristianfellowship.com
      </FooterTypography>
      <SocialIcons>
        <IconButton aria-label="facebook" color="inherit" component="a" href="https://www.facebook.com/profile.php?id=100072013372431" target="_blank">
          <FacebookIcon />
        </IconButton>
      </SocialIcons>
      <FooterTypography style={{ fontSize: '14px', marginBottom: '16px' }}>
        <FooterLink href="/privacy-policy-and-terms-of-service">Privacy Policy</FooterLink>
        <FooterLink href="/privacy-policy-and-terms-of-service">Terms of Service</FooterLink>
      </FooterTypography>
      <FooterTypography style={{ fontSize: '14px' }}>
        &copy; {new Date().getFullYear()} Living Faith Christian Fellowship. All rights reserved.
      </FooterTypography>
    </StyledFooter>
  );
};

export default Footer;
