import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Button, Box, Paper, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { FaArrowCircleDown } from 'react-icons/fa';
import Map from '../components/Map';
import FacebookIframeWithCTA from '../components/FacebookIframeWithCTA';
import HeroSection from '../components/HeroSection';
import SectionTitle from '../components/SectionTitle';
import { Helmet } from 'react-helmet-async';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: '100px',
  paddingBottom: '100px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 0,
  },
  zIndex: 1,
  backgroundImage: `url(/assets/logo.gif)`,
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

const ContentWrapper = styled(Box)({
  position: 'relative',
  zIndex: 1,
  textAlign: 'center',
});

const InfoCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '8px',
  marginBottom: theme.spacing(4),
  boxShadow: theme.shadows[3],
  padding: 40,
}));

const InfoContent = styled(CardContent)({
  fontFamily: 'Oswald, sans-serif',
});

const ContactCTA = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(4),
  textAlign: 'center',
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  marginBottom: theme.spacing(20),
  '& a': {
    textDecoration: 'none',
    color: 'white',
  },
  '& .MuiTypography-root': {
    marginBottom: theme.spacing(2),
  },
  '& .MuiButton-root': {
    marginTop: theme.spacing(2),
  },
}));

const HighlightBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  fontWeight: 'bold',
  marginLeft: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
}));

const Contact = () => {
  return (
    <Box>
      <Helmet>
        <title>Contact Us | Living Faith Christian Fellowship</title>
        <meta name="description" content="Get in touch with Living Faith Christian Fellowship to learn more about our services, events, and community outreach." />
        <link rel="canonical" href="https://livingfaithchristianfellowship.com/contact" />
      </Helmet>
      <HeroSection videoSrc='/assets/LFCF-IMAGES/Hero Video/2.mp4' title="Contact Us Today" scrollToId="#ContactUs" />
      
      <StyledContainer maxWidth={false}>
        <ContentWrapper>
          <SectionTitle id="ContactUs" variant="h2">Contact Page</SectionTitle>
          <Divider sx={{ backgroundColor: 'white', marginBottom: 5 }} />
          
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InfoCard>
                <CardMedia
                  component="img"
                  height="240"
                  image="/assets/logo.png"
                  alt="Mission"
                  sx={{ objectFit: 'contain' }}
                />
                <InfoContent>
                  <Typography variant="h5" gutterBottom>
                    Our Mission
                  </Typography>
                  <Typography variant="body1">
                    We aim to create a welcoming environment for everyone to explore and deepen their faith.
                  </Typography>
                </InfoContent>
                <InfoContent>
                  <Typography variant="h5" gutterBottom>
                    Community Outreach
                  </Typography>
                  <Typography variant="body1">
                    Join us in our efforts to make a positive impact in the community through various programs and events.
                  </Typography>
                </InfoContent>
                <InfoContent>
                  <Typography variant="h5" gutterBottom>
                    Service Times
                  </Typography>
                  <Typography variant="body1">Sunday Service: 10:00 AM - 11:30 AM</Typography>
                  <Typography variant="body1">Small Groups: Wednesdays at 7:00 PM</Typography>
                </InfoContent>
                <InfoContent>
                  <Typography variant="h5" gutterBottom>
                    Get Involved
                  </Typography>
                  <Typography variant="body1">
                    Contact us to learn more about our ministries and volunteer opportunities.
                  </Typography>
                </InfoContent>
              </InfoCard>
            </Grid>
          </Grid>

          <Box className="padding-bottom-pages">
            <Map />
          </Box>

          <ContactCTA>
            <CardMedia
              component="img"
              height="240"
              image="/assets/logo.png"
              alt="Contact Us"
              sx={{ objectFit: 'contain', marginBottom: 3 }}
            />
            <Typography variant="h5" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body1">Phone: +1 757 519 9763</Typography>
            <Typography variant="body1">Address: 5257 Challedon Dr, Virginia Beach, VA 23462, United States</Typography>
            <Typography variant="body1">Email: support@livingfaithchristianfellowship.com</Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 3 }}>
              <Typography variant="body1" gutterBottom>
                Need to contact us now? No worries!
              </Typography>
              <HighlightBox>
                Click The Contact Form Button Below <FaArrowCircleDown style={{ marginLeft: '8px' }} />
              </HighlightBox>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/contact-form"
                sx={{ marginTop: 2, color: 'white', width: '150px', margin: '0 auto' }}
              >
                Contact Form
              </Button>
            </Box>
          </ContactCTA>

          <FacebookIframeWithCTA />
        </ContentWrapper>
      </StyledContainer>
    </Box>
  );
};

export default Contact;
