import React from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import { motion } from 'framer-motion';
import FacebookIcon from '@mui/icons-material/Facebook';
import EventIcon from '@mui/icons-material/Event';

const IframeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(4),
  padding: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const MotionBox = styled(motion.div)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  margin: '16px',
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius * 2,
  maxWidth: '400px',
  width: '100%',
  textAlign: 'center',
  transition: 'transform 0.3s ease-in-out',
  background: 'white',
  color: 'black',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const CTAContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: theme.spacing(4),
  height: '100%',
}));

const MotionButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.primary.main,
  padding: '10px 20px',
  fontFamily: 'Oswald, sans-serif',
  fontSize: '16px',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const FacebookIframeWithCTA = () => {
  const location = useLocation(); // Get the current location

  return (
    <IframeContainer>
      <MotionBox
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        whileHover={{ scale: 1.05 }}
      >
        <StyledCard sx={{ height: { xs: 'auto', md: '400px' } }}>
          <CTAContent>
            <FacebookIcon fontSize="large" sx={{ mb: 2 }} />
            <Typography variant="h5" sx={{ fontFamily: 'Oswald, sans-serif', mb: 2 }}>
              Follow Us on Facebook
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Connect with us on Facebook to get the latest updates, events, and community activities!
            </Typography>
            <MotionButton
              variant="contained"
              href="https://www.facebook.com/profile.php?id=100072013372431"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Facebook Page
            </MotionButton>
          </CTAContent>
        </StyledCard>
      </MotionBox>

      {/* Conditionally render the schedule CTA based on the current path */}
      {location.pathname !== '/schedule' && (
        <MotionBox
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          whileHover={{ scale: 1.05 }}
        >
          <StyledCard sx={{ height: { xs: 'auto', md: '400px' } }}>
            <CTAContent>
              <EventIcon fontSize="large" sx={{ mb: 2 }} />
              <Typography variant="h5" sx={{ fontFamily: 'Oswald, sans-serif', mb: 2 }}>
                Check Our Event Schedule
              </Typography>
              <Typography variant="body1" sx={{ mb: 4 }}>
                Stay updated with our upcoming events and gatherings. Join us for services and special activities!
              </Typography>

              <MotionButton
                variant="contained"
                component={Link}
                to="/schedule"
              >
                View Full Schedule
              </MotionButton>
            </CTAContent>
          </StyledCard>
        </MotionBox>
      )}
    </IframeContainer>
  );
};

export default FacebookIframeWithCTA;
