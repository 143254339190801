import React from 'react';
import './App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import AppRoutes from './AppRoutes';
import { HelmetProvider } from 'react-helmet-async';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HelmetProvider >
      <div className="App">
        <Router>
          <NavBar />
          <ScrollToTop />
         
            <AppRoutes />
            </ Router>
            <Footer />
      </div>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
