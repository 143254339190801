import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Divider, Chip, Button, Grid } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { motion } from 'framer-motion';
import EventIcon from '@mui/icons-material/Event';
import PlaceIcon from '@mui/icons-material/Place';
import SectionTitle from './SectionTitle';

const events = [
  { title: 'Sunday Worship Service', subtitle: 'Worship and Fellowship', description: 'Join us every Sunday at 10 AM for our worship service.', date: 'Every Sunday, 10:00 AM', location: 'Main Hall', frequency: 'Weekly', photo: '/assets/LFCF-IMAGES/Misc/4.webp' },
  { title: 'Wednesday Prayer Gathering', subtitle: 'Midweek Prayer', description: 'Gather with us for prayer every Wednesday at 6:30 PM.', date: 'Every Wednesday, 6:30 PM', location: 'Prayer Room', frequency: 'Weekly', photo: '/assets/LFCF-IMAGES/Misc/3.webp' },
  { title: 'Friday Youth Night', subtitle: 'Youth Gathering', description: 'Youth nights every Friday at 7 PM.', date: 'Every Friday, 7:00 PM', location: 'Youth Center', frequency: 'Weekly', photo: '/assets/LFCF-IMAGES/Misc/2.webp' },
  { title: 'Cell Group Meetings', subtitle: 'Small Group Fellowship', description: 'Join one of our cell groups for fellowship and study.', date: 'Various days and times', location: 'Various Locations', frequency: 'Weekly', photo: '/assets/LFCF-IMAGES/CellGroupMeetings/Cell1.webp' },
  { title: 'Young Adult Fellowship', subtitle: 'Small Group Fellowship', description: 'Join one of our cell groups for fellowship and study.', date: 'Every other Monday', location: 'Various Locations', frequency: 'Bi-Weekly', photo: '/assets/LFCF-IMAGES/Misc/5.webp' },
];

const StyledCard = styled(Card)(({ theme }) => ({
  color: theme.palette.text.primary,
  margin: '16px auto',
  width: '100%',
  maxWidth: '600px',
  height: '700px',
  borderRadius: '15px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '90%', 
    height: 'auto',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
}));

const EventDetails = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: '8px',
});

const ImagePlaceholder = styled('img')(({ theme }) => ({
  width: '700px',
  height: '400px',
  objectFit: 'cover',
  [theme.breakpoints.down('sm')]: {
    height: '300px', 
    width: '90vw',
  },
}));

const HighlightReel = () => {
  const theme = useTheme(); 
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % events.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + events.length) % events.length);
  };

  return (
    <Box sx={{ textAlign: 'center', paddingBottom: 4, minHeight: '100vh' }}>
      <SectionTitle id="Highlights" variant="h4" sx={{ marginBottom: 2 }}>
        Highlights
      </SectionTitle>
      <Divider sx={{ marginBottom: 2 }} />
      <Grid container justifyContent="center">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <StyledCard theme={theme}>
            <ImagePlaceholder 
              loading="lazy" 
              theme={theme} 
              src={events[currentIndex].photo} 
              alt={events[currentIndex].title} />
            <CardContent>
              <Box sx={{ backgroundColor: '#F5EFEE', borderRadius: '10px', padding: 1 }}>
                <StyledTypography variant="h5" gutterBottom theme={theme}>
                  {events[currentIndex].title}
                </StyledTypography>
              </Box>
              <Divider />
              <StyledTypography variant="subtitle1" gutterBottom sx={{ color: theme.palette.text.secondary }} theme={theme}>
                {events[currentIndex].subtitle}
              </StyledTypography>
              <StyledTypography variant="body1" sx={{ marginBottom: 2 }}>
                {events[currentIndex].description}
              </StyledTypography>
              <EventDetails>
                <Chip sx={{ margin: '0 auto' }} label={events[currentIndex].date} icon={<EventIcon />} />
                <Chip sx={{ margin: '0 auto' }} label={events[currentIndex].location} icon={<PlaceIcon />} />
              </EventDetails>
              <Chip label={events[currentIndex].frequency} />
            </CardContent>
          </StyledCard>
        </motion.div>
      </Grid>

      <Box display="flex" justifyContent="center" mt={2}>
        <Button variant="contained" onClick={handlePrev} sx={{ marginRight: 2 }}>Previous</Button>
        <Button variant="contained" onClick={handleNext}>Next</Button>
      </Box>
    </Box>
  );
};

export default HighlightReel;
